import Button from "../../shared/SharedButton/SharedButton";
import {
  DecoyGrid,
  DiscoverCompaniesContainer,
  GradientOverlay,
  LinkStyled,
  OuterContainer,
  TitleContainer,
  TitleStyled,
} from "./styles";

function HomepageDiscoverCompanies(props) {
  return (
    <OuterContainer>
      <DiscoverCompaniesContainer>
        <GradientOverlay>
          <TitleContainer>
            <TitleStyled>Discover Our Top</TitleStyled>
            <TitleStyled>Hiring Companies</TitleStyled>
          </TitleContainer>
          <LinkStyled href={"/companies"}>
            <Button
              id={"homepage-discover-now-button"}
              button_type={"OutlinedWhite"}
              desktop_width={"Long"}
            >
              Discover Now
            </Button>
          </LinkStyled>
        </GradientOverlay>
      </DiscoverCompaniesContainer>
      <DecoyGrid />
    </OuterContainer>
  );
}

export default HomepageDiscoverCompanies;
