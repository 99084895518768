import Head from "next/head";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ToResolveClassNameError from "../components/hoc/ToResolveClassNameError";
import HomepageWrapper from "../components/homepage/HomepageWrapper/HomepageWrapper";
import SharedChat from "../components/shared/SharedChat/SharedChat";
import SharedFooter from "../components/shared/SharedFooter/SharedFooter";
import * as config from "../config/config";
import { updateShowSignInModalStatus } from "../redux/actions/navbar_action";
import { getUser } from "../redux/actions/user_action";

function Home(props) {
  const router = useRouter();
  const cUser = getUser();

  if (router.query.reset_password) {
    if (cUser) {
      router.push("/");
    } else {
      props.updateShowSignInModalStatus(true);
    }
  }

  let metaTitle =
    "Find Jobs in Malaysia | Vacancies & Career Opportunities - Hiredly";
  let metaDescription =
    "Find full-time, remote, work-from-home, and internship career opportunities in Malaysia. Experience easy and fast job search on one of Malaysia's leading job vacancy portals.";

  return (
    <Fragment>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" key={"ogType"} />
        <meta property="og:url" content="https://my.hiredly.com/" key="ogUrl" />
        <meta property="og:title" content={metaTitle} key="ogTitle" />
        <meta
          property="og:description"
          content={metaDescription}
          key="ogDescription"
        />
        <meta
          property="og:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="ogImage"
        />

        {/* <!-- Twitter --> */}
        <meta
          property="twitter:card"
          content="summary_large_image"
          key="twitterCard"
        />
        <meta
          property="twitter:url"
          content="https://my.hiredly.com/"
          key="twitterUrl"
        />
        <meta property="twitter:title" content={metaTitle} key="twitterTitle" />
        <meta
          property="twitter:description"
          content={metaDescription}
          key="twitterDescription"
        />
        <meta
          property="twitter:image"
          content={
            config.assetDomain + "/images/hiredly/hiredly-meta-image-v1.png"
          }
          key="twitterImage"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="canonical" href="https://my.hiredly.com/" />

        {/* iOS Smart App Banner */}
        <meta
          name="apple-itunes-app"
          content="app-id=981491669, app-argument=https://my.hiredly.com/"
        ></meta>
      </Head>
      <ToResolveClassNameError>
        <HomepageWrapper />
        {props.showMobileSearch ? null : (
          <Fragment>
            <SharedFooter />
            <SharedChat />
          </Fragment>
        )}
      </ToResolveClassNameError>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    showMobileSearch: state.navbar.showMobileSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShowSignInModalStatus: bindActionCreators(
      updateShowSignInModalStatus,
      dispatch
    ),
  };
};

export const getServerSideProps = async () => {
  const geoEnv = process.env.NEXT_PUBLIC_JSW_GEOLOCATION ?? "my";

  // if user website geolocation is set to SG, redirect them to the /jobs page
  if (geoEnv === "sg") {
    return {
      redirect: {
        permanent: false,
        destination: "/jobs",
      },
    };
  }

  return { props: {} };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
