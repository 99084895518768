import Link from "@mui/material/Link";
import { Fragment, useEffect, useState } from "react";
import { useSpring } from "react-spring";
import { generateDeviceType } from "../../../helpers/data_management";
import Button from "../../shared/SharedButton/SharedButton";
import {
  AppDownloadContent,
  ContinueText,
  DownloadAppButtonContainer,
  MobileWebButtonContainer,
  OverlayBackground,
  Title,
  TitleContainer,
} from "./styles";

const SharedDownloadAppBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const deviceType = generateDeviceType();

  // React Spring animation for download app banner
  const animateDownloadAppBanner = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
    config: { duration: 500 },
  });

  useEffect(() => {
    if (
      (deviceType == "web_mobile_ios" || deviceType == "web_mobile_android") &&
      sessionStorage.getItem("mobileAppBanner") == null
    ) {
      setShowBanner(true);
      sessionStorage.setItem("mobileAppBanner", "true"); // Only show download app banner once per session
    }
  }, []);

  const handleClick = () => {
    sessionStorage.setItem("mobileAppBanner", "false"); // Only show download app banner once per session
    setShowBanner(false);
  };

  return (
    <Fragment>
      {showBanner ? (
        <OverlayBackground>
          <AppDownloadContent style={animateDownloadAppBanner}>
            <TitleContainer>
              <Title>The Hiredly app is an easier way to look for jobs</Title>
            </TitleContainer>
            <DownloadAppButtonContainer>
              <Link
                underline={"none"}
                href="https://links.hiredly.com/get-the-app"
                rel="noopener"
                target="_blank"
              >
                <Button
                  button_type={"SolidPurple"}
                  text_transform={"uppercase"}
                  mobile_width={"80%"}
                  id={"mobile-download-app-button"}
                >
                  get the app
                </Button>
              </Link>
            </DownloadAppButtonContainer>
            <MobileWebButtonContainer>
              <ContinueText
                id={"mobile-continue-web-button"}
                onClick={handleClick}
              >
                Continue with mobile web
              </ContinueText>
            </MobileWebButtonContainer>
          </AppDownloadContent>
        </OverlayBackground>
      ) : null}
    </Fragment>
  );
};

export default SharedDownloadAppBanner;
