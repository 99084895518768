import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

// Image Component //
export const ImageStyled = styling("img")({
  width: "6.25rem",
  height: "6.25rem",
  borderRadius: "0.625rem",
  objectFit: "contain",
});

// Grid Components //
export const OuterGrid = styling(Grid)({
  height: "auto",
});

export const BlankGrid = styling(Grid)({
  //
});

export const DesktopImageContainer = styling(Grid)({
  width: "auto",
  justifyContent: "center",
  alignItems: "center",
});

export const ImageContainer = styling(Grid)((props) => ({
  backgroundColor: Color.white,
  transitionDuration: "0.5s",
  transform: props.transform,
  height: "100px",
  width: "100px",
  margin: "10px 0 10px 0",
  opacity: props.opacity,
  borderRadius: "10px",
  zIndex: 1,
  right: props.right,

  [props.theme.breakpoints.down("lg")]: {
    transitionDuration: "2.5s",
  },

  ":hover": {
    cursor: "none",
    transform: `scale(1.2)`,
  },
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "20px",
    padding: "5px",
    border: "3px solid black",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 5,
    cursor: "none",
  },
});

export const LinkStyled = styling("a")((props) => ({
  pointerEvents: props["aria-disabled"] ? "none" : "auto",
}));
