import React, { useEffect, useState } from "react";
import {
  TitleStyled,
  TitleStyledAnimated,
  AnimatedTextGrid,
  BlankGrid,
  HeaderGrid,
} from "./styles";

function HomepageAnimatedHeader(props) {
  // For the header animation
  const shuffleText = ["ultimate", "first", "dream", "ideal", "next"];
  const [pointer, setPointer] = useState(0);

  // For the pulsing animation
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);

      if (pointer === 4) {
        setPointer(0);
      } else {
        setPointer(pointer + 1);
      }
    }, 1300);
    return () => {
      clearTimeout(timer);
    };
  }, [time, pointer]);
  return (
    <HeaderGrid container item justifyContent="center" alignItems="center">
      <TitleStyled variant="h3">Find your </TitleStyled>
      <BlankGrid />
      {shuffleText.map((text, index) => {
        return (
          <AnimatedTextGrid
            key={text}
            container
            justifyContent="center"
            alignment="center"
            opacity={pointer === index ? 1 : 0}
          >
            <TitleStyledAnimated variant="h3">{text}</TitleStyledAnimated>
          </AnimatedTextGrid>
        );
      })}
      <TitleStyled variant="h3"> job</TitleStyled>
    </HeaderGrid>
  );
}

export default HomepageAnimatedHeader;
