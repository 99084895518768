import Search from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../../assets/colors";
import { FontSize } from "../../../../assets/font";

// Grid
export const TitleGrid = styling(Grid)((props) => ({
  maxHeight: "180px",
  paddingTop: "36px",
  paddingBottom: "20px",

  [props.theme.breakpoints.down("lg")]: {
    height: "100%",
  },

  [props.theme.breakpoints.down("tablet")]: {
    paddingTop: "24px",
    paddingBottom: "12px",
    maxHeight: "1800px",
  },
}));

export const SuggestionsContainerWrapper = styling(Grid)((props) => ({
  zIndex: 10,
}));

export const HeaderGrid = styling(Grid)({
  width: "100%",
});

export const SearchBarGrid = styling(Grid)((props) => ({
  width: "100%",
}));

export const AnimatedTextGrid = styling(Grid)((props) => ({
  minWidth: "220px",
  maxWidth: "220px",
  height: "auto",
  position: "absolute",
  transitionDuration: "0.35s",
  opacity: props.opacity,
  marginLeft: "145px",

  [props.theme.breakpoints.down("lg")]: {
    marginLeft: "145px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    marginLeft: "85px",
  },
}));

export const BlankGrid = styling(Grid)((props) => ({
  minWidth: "220px",

  [props.theme.breakpoints.down("lg")]: {
    minWidth: "220px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    minWidth: "130px",
  },
}));

export const LandingContainer = styling(Grid)(({ theme }) => ({
  width: "100%",
  height: "700px",
  padding: "100px 100px 0 100px",

  [theme.breakpoints.down("tabletS")]: {
    padding: "100px 50px 0 50px",
  },

  [theme.breakpoints.down("mobileL")]: {
    padding: "100px 25px 0 25px",
  },
}));

export const BlankContainer = styling(Grid)({});

export const SuggestionsContainer = styling(Grid)((props) => ({
  borderRadius: "10px",
  backgroundColor: Color.white,
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
  width: "60%",
  minWidth: "520px",
  maxWidth: "520px",
  position: "relative",
  boxShadow: "3px 3px 0px 0px rgba(0,0,0,1)",
  border: `2px solid ${Color.chipGrey}`,
  zIndex: 10,
  position: "absolute",

  [props.theme.breakpoints.down("tablet")]: {
    minWidth: "328px",
    maxWidth: "328px",
  },
}));

export const SearchSuggestionContainer = styling(Grid)({
  padding: "16px 20px",
  width: "100%",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: Color.lightHiredlyPurple,
    cursor: "pointer",
  },
});

export const SubtitleContainer = styling(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "25px 0",

  [theme.breakpoints.down("mobileL")]: {
    margin: "10px 10px 20px 0",
  },
}));

// Typography
export const TitleStyled = styling(Typography)((props) => ({
  color: Color.black,
  fontWeight: "bold",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "28px",
  },
}));

export const TitleStyledAnimated = styling(Typography)((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "bold",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "28px",
  },
}));

export const SubtitleStyled = styling(Typography)(({ theme }) => ({
  color: Color.white,
  fontSize: FontSize.desktopTitle1,

  [theme.breakpoints.down("mobileL")]: {
    fontSize: FontSize.desktopHeadline2,
  },
}));

// Icon Components //
export const IconButtonStyled = styling(IconButton)((props) => ({
  backgroundColor:
    props.hover === "true" || props.searchbarfocus
      ? Color.hiredlyPurple
      : Color.white,
  color: Color.white,
  margin: "4px",
  borderRadius: "5px",
  transitionDuration: "0.5s",
  zIndex: 10,

  "&.MuiButtonBase-root:hover": {
    backgroundColor: Color.hiredlyPurple,

    "& .MuiSvgIcon-root": {
      color: Color.white,
    },
  },
}));

export const SearchIconStyledContained = styling(Search)((props) => ({
  fontSize: "1.5rem",
  color:
    props.hover === "true" || props.focused === "true"
      ? Color.white
      : Color.hiredlyPurple,
  transitionDuration: "0.5s",
  zIndex: 10,

  ":hover": {
    color: Color.white,
  },
}));

export const SearchIconStyledOriginal = styling(Search)((props) => ({
  fontSize: "1.5rem",
  color:
    props.hover === "true" || props.focused === "true"
      ? Color.white
      : Color.hiredlyPurple,
  transitionDuration: "0.5s",
  zIndex: 100,
}));

export const MobileSearchStyled = styled(Search)`
  && {
    color: ${Color.hiredlyPurple};
    font-weight: ${FontSize.light};
    margin-right: 5px;
  }
`;

// Input Base Component //
export const InputBaseStyled = styling(InputBase)(({ theme }) => ({
  width: "100%",

  "::placeholder": {
    color: "red",
  },

  [theme.breakpoints.down("tablet")]: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.8)",
  },
}));

// Paper Component //
export const SearchBarStyled = styling(Paper)(({ theme }) => ({
  borderRadius: "8px",
  width: "60%",
  minWidth: "520px",
  maxWidth: "520px",
  padding: "8px 8px 8px 24px",
  backgroundColor: Color.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: `2px solid ${Color.chipGrey}`,
  height: "64px",
  boxShadow: "3px 3px 0px 0px rgba(0,0,0,1)",
  marginBottom: "10px",

  [theme.breakpoints.down("tablet")]: {
    padding: "8px 8px 8px 16px",
    minWidth: "328px",
    maxWidth: "328px",
    height: "50px",
  },
}));

// Chip Component //
export const SubtitleLeftElement = withTheme(styled.img`
  && {
    width: 30px;
    height: 30px;
    transform: translateY(-14px);

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      transform: translateY(-25px);
    }
  }
`);

export const SubtitleRightElement = withTheme(styled.img`
  && {
    height: 45px;
    transform: translateY(20px) translateX(20px);
  }
`);

export const LandingBannerImage = withTheme(styled.img`
  && {
    width: 100%;
    height: 700px;
    position: absolute;
    z-index: -1;
    object-fit: cover;

    ${(props) => props.theme.breakpoints.down("md")} {
      height: 680px;
    }

    ${(props) => props.theme.breakpoints.up("lgXl")} {
      height: 850px;
      object-position: top;
    }
  }
`);
