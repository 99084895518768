import Search from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid Components //
export const TrendingSearchesOuterWrapper = styling(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: Color.homepageGrey,
}));

export const TrendingSearchesContainer = styling(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "100px 0 100px 0",

  [theme.breakpoints.up("lgXl")]: {
    padding: "150px 0",
    maxWidth: "1026px",
  },

  [theme.breakpoints.down("lg")]: {
    padding: "50px 0 100px 0",
  },

  [theme.breakpoints.down("tablet")]: {
    padding: "0 0 80px 0",
  },
}));

export const ChipsContainer = styling(Grid)(({ theme }) => ({
  width: "80%",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  margin: "35px 0",

  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    padding: "0 0 0 0",
  },
}));

export const CategoryIconGrid = styled(Grid)`
  && {
    background-color: ${Color.white};
    border-radius: 50%;
    width: 3.25rem;
    height: 3.25rem;
    color: ${Color.hiredlyPurple};

    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

// Box Component //
export const CategoryBox = styling(Box)({
  backgroundColor: Color.lightPurple,
  borderRadius: "20px",
  padding: "1rem",
  cursor: "pointer",
  columnGap: "0.5rem",
  margin: "0.5rem",

  ":hover": {
    backgroundColor: Color.hiredlyPurple,
    color: Color.white,
  },
});

export const CategoryBoxItem = styling(Box)({
  alignSelf: "center",
  justifySelf: "center",
});

// Typography Component //
export const TitleStyled = styling(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: FontSize.bold,

  [theme.breakpoints.down("tablet")]: {
    fontSize: FontSize.desktopHeader,
  },
}));

export const CategoryTitle = styling(Typography)({});

// Chip Component //
export const ChipStyled = styling(Chip)((props) => ({
  backgroundColor: "rgba(81, 42, 204, 0.1)",
  padding: "5px 10px",
  margin: "10px 5px",

  "&:hover": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  "&:focus": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  fontSize: props.width > 748 ? "16px" : "",
  borderRadius: props.width > 748 ? "30px" : "",
  padding: props.width > 748 ? "20px 10px" : "5px 10px",
}));

// Icon Component //
export const SearchIconStyled = styling(Search)((props) => ({
  fontSize: props.width ? "1.4rem" : "1rem",
}));

export const LinkStyled = styling(Link)(({ theme }) => ({
  textDecoration: "none",
}));
