import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import { getTrendingChip } from "../../../../redux/actions/job_action";
import {
  BlankContainer,
  BoxContainer,
  ChipContainer,
  ChipStyled,
  TrendingContainer,
  TrendingTextBox,
  TypographyStyled,
} from "./styles";

export default function HomepageTrending(props) {
  const dispatch = useDispatch();
  const router = useRouter();

  const trendingChip = useSelector((state) => state?.jobs?.trendingChip);

  const redirectToJobs = (searchTerm) => {
    // GTM tracking custom event for tracking clicks of trending chips
    sendTrackingEvent({
      event: "CE_click-search-job-trending-header",
      "search-term": searchTerm,
    });

    const cleanSearchTerm =
      searchTerm.toLowerCase()?.replace(/\s+/g, "-") + "-jobs";
    router.push(`/${cleanSearchTerm}`);
  };

  const screenMobile = useMediaQuery("(min-width:768px)");

  useEffect(() => {
    dispatch(getTrendingChip({ first: 5 }));
  }, [dispatch]);

  return Array.isArray(trendingChip) && trendingChip.length > 0 ? (
    <TrendingContainer container direction="row" alignItems="flex-start">
      <BlankContainer container justifyContent="center" alignItems="center">
        <TrendingTextBox>
          <TypographyStyled variant="body1_bold_L">Trending</TypographyStyled>
        </TrendingTextBox>
      </BlankContainer>

      <ChipContainer
        container
        rowGap={"10px"}
        columnGap={screenMobile ? "10px" : "4px"}
      >
        {trendingChip.map((chip, index) => {
          return (
            <ChipStyled
              key={index}
              label={chip?.title}
              clickable
              variant="outlined"
              size="small"
              onClick={() => redirectToJobs(chip?.title)}
            />
          );
        })}
      </ChipContainer>
    </TrendingContainer>
  ) : (
    <BoxContainer height={"58px"}></BoxContainer>
  );
}
