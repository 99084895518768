import Clear from "@mui/icons-material/Clear";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import RecentSearches from "recent-searches";
import { bindActionCreators } from "redux";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  getTrendingJobKeywords,
} from "../../../../redux/actions/job_action";
import { store } from "../../../../redux/stores/store";
import {
  BlankContainer,
  IconButtonStyled,
  InputBaseStyled,
  SearchBarGrid,
  SearchBarStyled,
  SearchIconStyledContained,
  SearchIconStyledOriginal,
  SearchSuggestionContainer,
  SuggestionsContainer,
  SuggestionsContainerWrapper,
  TitleGrid,
} from "./styles";

function HomepageSearchBar(props) {
  // Logics for search bar
  const textInputRef = useRef(null);
  const [search, setSearch] = useState("");
  const [searchBarFocus, setSearchBarFocus] = useState(false);
  const [searchingJobs, setSearchingJobs] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [width, setWidth] = useState(0);
  const router = useRouter();

  const dispatch = useDispatch();

  const screenMobile = useMediaQuery("(min-width:768px)");

  const searches = new RecentSearches({
    namespace: "recent-searches",
    ranking: "TIME",
    limit: 5,
  });

  const compareSize = () => {
    setWidth(window.innerWidth);
  };

  const onSearchBarClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const searchOnClickListener = (e) => {
      if (event !== e) {
        setSearchBarFocus(false);
        window.removeEventListener("click", searchOnClickListener);
      }
    };

    if (!searchBarFocus) {
      setSearchBarFocus(true);
      window.addEventListener("click", searchOnClickListener);
    }

    if (width <= 768) {
      store.getState().jobs.fromMobileNavbar = false;
    }
  };

  const onSearchUpdate = (event) => {
    setSearch(event.target.value);

    if (event.target.value.length < 2) {
      return;
    } else {
      setTimeout(() => {
        let params = {
          keyword: event.target.value,
        };
        props.fetchSearchSuggestions(params);
      }, 100);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearchingJobs(true);
      handleSearchButtonClicked();
    }
  };

  const handleClearSearchInput = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSearch("");
    textInputRef.current.value = "";
  };

  const handleSearchButtonClicked = () => {
    const searchUrl = search
      ? search.toLowerCase()?.replace(/\s+/g, "-") + "-jobs/"
      : "/jobs";

    router.push(searchUrl);
  };

  const handleSearchSuggestionClicked = (suggestion) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    const searchUrl = suggestion.toLowerCase()?.replace(/\s+/g, "-") + "-jobs/";
    router.push(searchUrl);
  };

  const SearchSuggestions = (props) => {
    const { suggestions, search, handleSearchSuggestionClicked } = props;

    const boldMatchingText = (suggestion, searchInput) => {
      const n = suggestion.toLowerCase();
      const q = searchInput.toLowerCase();
      const x = n.indexOf(q);

      // If suggestion has no matching characters, return suggestion
      if (!q || x === -1) {
        return (
          <SearchSuggestionContainer
            onClick={handleSearchSuggestionClicked(suggestion)}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: suggestion,
              }}
            />
          </SearchSuggestionContainer>
        );
      }
      // Else bold characters that match search input
      const l = q.length;
      const boldedSearch =
        suggestion.substr(0, x) +
        "<b>" +
        suggestion.substr(x, l) +
        "</b>" +
        suggestion.substr(x + l);

      return (
        <SearchSuggestionContainer
          onClick={handleSearchSuggestionClicked(suggestion)}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: boldedSearch,
            }}
          />
        </SearchSuggestionContainer>
      );
    };

    return (
      <Fragment>
        {suggestions.length > 0 && (
          <BlankContainer container justifyContent="center">
            <SuggestionsContainer>
              {suggestions.map((suggestion, index) => {
                return (
                  <div key={index}>
                    {boldMatchingText(suggestion.name, search)}
                  </div>
                );
              })}
            </SuggestionsContainer>
          </BlankContainer>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    let params = { limit: 10 };
    props.getTrendingJobKeywords(params);

    setRecentSearches(searches.getRecentSearches("recent-searches"));

    // Compare once and add resize listener on "componentDidMount"
    window.addEventListener("resize", compareSize);

    if (!width) {
      setWidth(window.innerWidth);
    }

    return () => {
      // Remove resize listener again on "componentWillUnmount"
      window.removeEventListener("resize", compareSize);
      setRecentSearches(searches.getRecentSearches("recent-searches"));
    };
  }, []);

  // For the header animation
  const shuffleText = ["ultimate", "desired", "fun", "cool", "liked"];
  const [pointer, setPointer] = useState(0);

  // For the pulsing animation
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);

      if (pointer === 4) {
        setPointer(0);
      } else {
        setPointer(pointer + 1);
      }
    }, 1300);
    return () => {
      clearTimeout(timer);
    };
  }, [time, pointer]);

  // For the search bar search icon hover effect
  const [hover, setHover] = useState("false");

  return (
    <TitleGrid container justifyContent="center" alignItems="center">
      <SearchBarGrid
        container
        direction="column"
        item
        justifyContent="center"
        alignItems="center"
        lg={12}
      >
        <SearchBarStyled>
          <InputBaseStyled
            onMouseEnter={() => {
              setHover("true");
            }}
            onMouseLeave={() => {
              setHover("false");
            }}
            placeholder={"Search by job titles, companies or skills"}
            inputProps={{
              style: { fontStyle: search.length > 0 ? "normal" : "italic" },
            }}
            onClick={onSearchBarClicked}
            onChange={onSearchUpdate}
            onKeyDown={onKeyDown}
            defaultValue={search ? search : ""}
            inputRef={textInputRef}
          />
          {searchBarFocus && search.length > 2 && (
            <IconButton
              onClick={handleClearSearchInput}
              sx={{ borderRadius: "5px" }}
            >
              <Clear sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          )}
          {searchBarFocus ? (
            <IconButtonStyled
              onClick={handleSearchButtonClicked}
              hover={hover}
              searchbarfocus={searchBarFocus}
            >
              <SearchIconStyledContained
                hover={hover}
                focused={searchBarFocus.toString()}
                sx={{ fontSize: screenMobile ? "34px" : "24px" }}
              />
            </IconButtonStyled>
          ) : (
            <Fragment>
              <IconButtonStyled
                onClick={handleSearchButtonClicked}
                hover={hover}
              >
                <SearchIconStyledOriginal
                  hover={hover}
                  focused={searchBarFocus.toString()}
                  sx={{ fontSize: screenMobile ? "34px" : "24px" }}
                />
              </IconButtonStyled>
            </Fragment>
          )}
        </SearchBarStyled>
        {search.length > 2 ? (
          <Fade in={search.length > 2} timeout={400}>
            <SuggestionsContainerWrapper>
              <SearchSuggestions
                suggestions={props.searchData}
                search={search}
                handleSearchSuggestionClicked={handleSearchSuggestionClicked}
              />
            </SuggestionsContainerWrapper>
          </Fade>
        ) : null}
      </SearchBarGrid>
    </TitleGrid>
  );
}

const mapStateToProps = (state) => {
  return {
    trendingJobKeywords: state.jobs.trendingJobKeywords,
    searchData: state.jobs.searchData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrendingJobKeywords: bindActionCreators(
      getTrendingJobKeywords,
      dispatch
    ),
    fetchSearchSuggestions: bindActionCreators(
      fetchSearchSuggestions,
      dispatch
    ),
    clearSearchSuggestions: bindActionCreators(
      clearSearchSuggestions,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageSearchBar);
