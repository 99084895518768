import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import { styled as styling } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import AccessTime from "@mui/icons-material/AccessTime";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

// Paper Components //
export const PaperStyled = styling(Paper)({
  backgroundColor: "#f4f6ff",
  width: "100vw",
  height: "100vh",
  padding: "30px",
  overflow: "scroll",
});

export const SearchBarStyled = styling(Paper)({
  borderRadius: "50px",
  width: "100%",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
  marginTop: "12px",
  backgroundColor: Color.white,
  display: "flex",
  alignItems: "center",
});

// Grid Components //
export const CloseIconContainer = styling(Grid)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "30px",
});

export const SearchesContainer = styling(Grid)({
  width: "100%",
  marginTop: "30px",
});

export const ChipsContainer = styling(Grid)({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  marginTop: "10px",
});

export const RecentSearchTermContainer = styling(Grid)({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 0 0 10px",
  borderRadius: "50px",

  "&:hover": {
    backgroundColor: "rgba(132, 132, 132, 0.1)",
  },
});

export const IconTextContainer = styling(Grid)({
  display: "flex",
  width: "100%",
  alignItems: "center",
  cursor: "pointer",
});

export const SuggestionsContainer = styling(Grid)({
  padding: "20px",
});

export const SearchSuggestionContainer = styling(Grid)({
  margin: "0 0 25px 0",
});

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopHeader};
    font-weight: ${FontSize.bold};
    font-family: "Poppins", sans-serif;
    color: ${Color.hiredlyPurple};
  }
`;
export const SubtitleStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
    font-weight: ${FontSize.bold};
  }
`;
export const SearchTermStyled = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
    color: ${Color.black};
  }
`;

// Icon Components //
export const CloseIconStyled = styled(CloseIcon)`
  && {
    color: ${Color.hiredlyPurple};
    font-size: 2.5rem;
  }
`;
export const SearchIconStyled = styled(Search)`
  && {
    font-size: 1rem;
  }
`;
export const TimeIconStyled = styled(AccessTime)`
  && {
    font-weight: 300;
    margin-right: 12px;
    color: rgba(132, 132, 132, 0.6);
  }
`;
export const DeleteIconStyled = styled(DeleteOutline)`
  && {
    font-weight: 300;
    color: rgba(132, 132, 132, 0.6);
  }
`;
export const IconButtonStyled = styled(IconButton)`
  && {
    padding: 0;
  }
`;
export const ClearIconButtonStyled = styled(IconButton)`
  && {
    margin-right: 10px;
  }
`;

// Misc. Components //
export const InputBaseStyled = styling(InputBase)({
  width: "100%",
  fontSize: FontSize.desktopCaption,
  padding: "8px 20px",
});

export const ChipStyled = styling(Chip)({
  backgroundColor: "rgba(81, 42, 204, 0.1)",
  padding: "10px",
  margin: "10px 5px",

  "&:hover": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  "&:focus": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },
});
