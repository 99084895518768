import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faSpotify,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import NextLink from "next/link";
import { Fragment, useEffect, useState } from "react";
import * as config from "../../../config/config";
import { setEmployerParams } from "../../../helpers/multicountry_management";
import {
  ColumnContainer,
  ColumnContainerMiddle,
  ColumnLink,
  CompanyTitleStyled,
  ContainerStyled,
  ContentContainer,
  CopyrightContainer,
  CopyrightContainerMiddle,
  CopyrightText,
  DownloadAppLogo,
  FirstContentContainer,
  GridIcons,
  GridIconsContainer,
  GridLinks,
  GridLinksContainer,
  GridMission,
  IconStyled,
  LinkExternal,
  LinkIcon,
  LinkStyled,
  LinkText,
  MissionText,
  QrCode,
  SpanStyled,
  VanillaLinkStyled,
  VerticalSeparator,
} from "./styles";

export default function SharedFooter() {
  let year = new Date().getFullYear();
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <Fragment>
      {hasMounted == true ? (
        <ContainerStyled>
          <ContentContainer>
            <FirstContentContainer>
              <ColumnContainer section="mission">
                <GridLinksContainer>
                  <CompanyTitleStyled>hiredly</CompanyTitleStyled>
                  <div>
                    <GridMission>
                      <MissionText>
                        Our vision is to be SEA's most personalised talent
                        ecosystem; elevating human progress by helping careers
                        and companies grow.
                      </MissionText>
                    </GridMission>
                  </div>
                </GridLinksContainer>
              </ColumnContainer>

              <ColumnContainerMiddle>
                <ColumnLink>
                  <CompanyTitleStyled>hiredly</CompanyTitleStyled>
                  <GridLinks>
                    <VanillaLinkStyled href="/about-us" legacyBehavior>
                      <LinkText id={"footer-about-us-button"}>
                        about us
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/contact-us" legacyBehavior>
                      <LinkText id={"footer-contact-us-button"}>
                        contact us
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/newsroom" legacyBehavior>
                      <LinkText id={"footer-newsroom-button"}>
                        newsroom
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/faq" legacyBehavior>
                      <LinkText id={"footer-faq-button"}>FAQ</LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled
                      href="/companies/hiredly-x"
                      legacyBehavior
                    >
                      <LinkText id={"hiredly-x-button"}>Hiredly X</LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/updates" legacyBehavior>
                      <LinkText id={"footer-updates-button"}>Updates</LinkText>
                    </VanillaLinkStyled>
                  </GridLinks>
                </ColumnLink>
                <ColumnLink>
                  <CompanyTitleStyled>jobseeker</CompanyTitleStyled>
                  <GridLinks>
                    <VanillaLinkStyled
                      href="/sitemap/jobs-by-specialisation"
                      legacyBehavior
                    >
                      <LinkText id={"footer-jobs-by-specialisation-button"}>
                        jobs <SpanStyled>by</SpanStyled> specialisation
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled
                      href="/sitemap/jobs-by-location"
                      legacyBehavior
                    >
                      <LinkText id={"footer-jobs-by-state-button"}>
                        jobs <SpanStyled>by</SpanStyled> location
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled
                      href="/sitemap/jobs-by-type"
                      legacyBehavior
                    >
                      <LinkText id={"footer-jobs-by-type-button"}>
                        jobs <SpanStyled>by</SpanStyled> type
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled
                      href="/sitemap/jobs-by-experience-level"
                      legacyBehavior
                    >
                      <LinkText id={"footer-jobs-by-experience-level-button"}>
                        jobs <SpanStyled>by</SpanStyled> experience level
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/companies" legacyBehavior>
                      <LinkText id={"footer-companies-button"}>
                        companies
                      </LinkText>
                    </VanillaLinkStyled>
                    <VanillaLinkStyled href="/advice" legacyBehavior>
                      <LinkText id={"footer-advice-button"}>advice</LinkText>
                    </VanillaLinkStyled>
                  </GridLinks>
                </ColumnLink>
                <ColumnLink>
                  <CompanyTitleStyled>employer</CompanyTitleStyled>
                  <GridLinks>
                    <LinkExternal
                      href={
                        process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                          ? "http://hub.hiredly.com/hiredly-sg-employer-waitlist"
                          : setEmployerParams()
                      }
                    >
                      <LinkText id={"footer-start-hiring-button"}>
                        start hiring today
                      </LinkText>
                    </LinkExternal>
                    <LinkExternal href="https://x.hiredly.com/employer">
                      <LinkText id={"footer-headhunting-button"}>
                        headhunting service
                      </LinkText>
                    </LinkExternal>
                  </GridLinks>
                </ColumnLink>
              </ColumnContainerMiddle>
            </FirstContentContainer>

            <ColumnContainer section="download_app_logo">
              <LinkStyled
                href="https://itunes.apple.com/us/app/wobb/id981491669?mt=8"
                rel="noopener"
                target="_blank"
              >
                <DownloadAppLogo
                  src={
                    config.assetDomain +
                    "/images/hiredly/app_store_download_button.svg"
                  }
                  alt="app_store_download_button"
                />
              </LinkStyled>
              <LinkStyled
                href="https://play.google.com/store/apps/details?id=com.wobb.hunted&hl=en"
                rel="noopener"
                target="_blank"
              >
                <DownloadAppLogo
                  src={
                    config.assetDomain +
                    "/images/hiredly/google_play_download_button.svg"
                  }
                  alt="google_play_download_button"
                />
              </LinkStyled>
              <LinkStyled
                href="https://appgallery.huawei.com/#/app/C101027941?channelId=homepage-footer&id=8c375cc9194d469488f3f163394cfbd5&s=EDFF426DF95D1FCBCCE03918FB64DFF9C7197D34B481DE8A2BE8654FDF391EFB&detailType=0&v="
                rel="noopener"
                target="_blank"
              >
                <DownloadAppLogo
                  src={
                    config.assetDomain +
                    "/images/hiredly/app_gallery_download_button.svg"
                  }
                  alt="app_gallery_download_button"
                />
              </LinkStyled>
              <QrCode
                src={
                  config.assetDomain +
                  "/images/landing/onelink-app-download-qr.png"
                }
                alt="app_gallery_download_button"
              />
            </ColumnContainer>
          </ContentContainer>

          <CopyrightContainer>
            <GridIconsContainer>
              <GridIcons>
                <LinkIcon
                  id={"footer-facebook-button"}
                  href="https://www.facebook.com/hiredly"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faFacebook} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-instagram-button"}
                  href="https://www.instagram.com/growwithhiredly/ "
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faInstagram} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-tiktok-button"}
                  href="https://www.tiktok.com/@growwithhiredly "
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faTiktok} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-linkedin-button"}
                  href="https://www.linkedin.com/companies/hiredlymy/"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faLinkedinIn} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-spotify-button"}
                  href="https://open.spotify.com/user/hjpf36a3ey64jq35xxx2d2rzd?si=441f177d08d74926"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faSpotify} />
                </LinkIcon>
                <LinkIcon
                  id={"footer-youtube-button"}
                  href="https://www.youtube.com/hiredly"
                  rel="noopener"
                  target="_blank"
                >
                  <IconStyled icon={faYoutube} />
                </LinkIcon>
              </GridIcons>
            </GridIconsContainer>

            <CopyrightText>
              {`© Copyright 2014 - ${year} Agensi Pekerjaan Wobb Sdn. Bhd.`}
            </CopyrightText>

            <CopyrightContainerMiddle>
              <NextLink href="/community-guidelines" legacyBehavior>
                <LinkText id={"footer-community-guidelines-button"}>
                  Community Guidelines
                </LinkText>
              </NextLink>
              <VerticalSeparator></VerticalSeparator>
              <NextLink href="/privacy-policy" legacyBehavior>
                <LinkText id={"footer-privacy-policy-button"}>
                  Privacy Policy
                </LinkText>
              </NextLink>
              <VerticalSeparator></VerticalSeparator>
              <NextLink href="/terms-and-conditions" legacyBehavior>
                <LinkText id={"footer-terms-and-conditions-button"}>
                  Terms & Conditions
                </LinkText>
              </NextLink>
              <VerticalSeparator></VerticalSeparator>
              <VanillaLinkStyled href="/sitemap" legacyBehavior>
                <LinkText id={"footer-sitemap-button"}>Site Map</LinkText>
              </VanillaLinkStyled>
            </CopyrightContainerMiddle>
          </CopyrightContainer>
        </ContainerStyled>
      ) : null}
    </Fragment>
  );
}
