import Grid from "@mui/material/Grid";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const Wrapper = styling(Grid)(({ theme }) => ({
  maxWidth: "1440px",
  padding: "0 100px",
  backgroundColor: Color.homepageGrey,

  [theme.breakpoints.down("tablet")]: {
    padding: "0 20px",
  },
}));

export const BannerHomepageWrapper = styling(Grid)(({ theme }) => ({
  backgroundColor: Color.homepageGrey,
  [theme.breakpoints.down("tablet")]: {
    marginTop: "50px",
  },
}));
