import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Grid Components //
export const ContainerStyled = styled(Grid)`
  && {
    background-color: ${Color.homepageGrey};
    width: 100%;
    color: ${Color.black};
    padding: 32px 60px;
  }
`;
export const GridLinks = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    max-width: 400px;
  }
`;
export const GridLinksContainer = styled(Grid)`
  && {
    width: 100%;
  }
`;
export const GridIcons = styled(Grid)`
  && {
    display: flex;
    flex-wrap: no-wrap;
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }
`;
export const GridMission = withTheme(styled(Grid)`
  && {
    width: 100%;
  }
`);
export const GridIconsContainer = withTheme(styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.down("mobileL")} {
      margin: 0 0 10px 0;
    }

    ${(props) => props.theme.breakpoints.between("mobileL", "tabletS")} {
      width: 40%;
    }
  }
`);
export const GridLinkedin = withTheme(styled(Grid)`
  && {
    margin: 30px 0;
  }
`);
export const ContentContainer = withTheme(styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`);
export const ColumnContainer = withTheme(styled(Grid)`
  && {
    ${(props) =>
      props.section === "download_app_logo" &&
      css`
        display: grid;
      `}

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      ${(props) => props.section === "mission" && css``}

      ${(props) =>
        props.section === "download_app_logo" &&
        css`
          width: 100%;
          margin: 0 0 20px;
        `}
    }
  }
`);
export const ColumnContainerMiddle = withTheme(styled(Grid)`
  && {
    display: grid;
    grid-template-columns: 20% repeat(2, 30%);
    gap: 20px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: block;
      margin: 20px 0;
    }
  }
`);
export const CopyrightContainer = withTheme(styled(Grid)`
  && {
    margin: 1.75rem 0 0;
    display: flex;
    justify-content: space-between;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: block;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      flex-wrap: wrap;
    }
  }
`);
export const CopyrightContainerMiddle = withTheme(styled(Grid)`
  && {
    margin: 0;
    display: flex;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      margin: 20px 0 0;
    }
  }
`);
export const FirstContentContainer = withTheme(styled.div`
  && {
    display: grid;
    grid-template-columns: 40% auto;
    width: 90%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`);
export const VerticalSeparator = withTheme(styled.div`
  && {
    width: 2px;
    height: 15px;
    background-color: black;
    margin: 0 12px;
    transform: translateY(3px);

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      height: 35px;
    }
  }
`);
export const ColumnLink = withTheme(styled(Grid)`
  && {
    margin: 0;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      margin: 0 0 20px 0;
    }
  }
`);

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    text-transform: capitalize;
    font-weight: ${FontSize.bold};
    font-size: ${FontSize.desktopHeadline2};
    margin-bottom: 20px;
  }
`;
export const CompanyTitleStyled = styled(Typography)`
  && {
    text-transform: capitalize;
    font-weight: ${FontSize.bold};
    font-size: 1.25rem;
    margin-bottom: 8px;
  }
`;
export const CopyrightText = withTheme(styled(Typography)`
  && {
    font-size: 0.8rem;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      margin: 20px 0 0;
    }
  }
`);
export const MissionText = styled(Typography)`
  && {
    font-size: 0.875rem;
    text-align: left;
    width: 85%;
  }
`;
export const SpanStyled = styled.span`
  text-transform: lowercase;
`;
export const LinkText = styled.span`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
    color: ${Color.black};
    cursor: pointer;
    margin-bottom: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      border-color: ${Color.black};
    }
  }
`;

// Link Components //
export const LinkIcon = styled(Link)`
  && {
    color: ${Color.black};
  }
`;
export const LinkStyled = styled(Link)`
  && {
    width: 150px;
  }
`;
export const LinkExternal = styled(Link)`
  && {
    margin-bottom: 15px;
    text-decoration: none;
  }
`;

// Icon Components //
export const IconStyled = withTheme(styled(FontAwesomeIcon)`
  && {
    margin-right: 20px;
    width: auto;
    height: 20px;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      cursor: pointer;
      height: 25px;
      width: auto;
    }

    &:hover {
      color: ${Color.hiredlyPurple};
    }
  }
`);

// Image Components //
export const DownloadAppLogo = withTheme(styled.img`
  && {
    max-width: 100%;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      max-width: 100%;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      max-width: 100%;
    }
  }
`);
export const QrCode = withTheme(styled.img`
  && {
    width: 70%;
    height: auto;
    margin: auto;

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: none;
    }
  }
`);
export const LinkedinLogo = withTheme(styled.img`
  && {
    width: 30%;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 40%;
    }
  }
`);

export const VanillaLinkStyled = styling("a")({
  textDecoration: "none",
  marginBottom: "15px",
  cursor: "pointer",
});
