import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import { getHomepageAds } from "../../../redux/actions/job_action";
import {
  Atag,
  BannerChipStyled,
  BannerChipWrapper,
  BannerDescription,
  BannerDescriptionWrapper,
  BannerDetailsWrapper,
  BannerPaginationActiveText,
  BannerPaginationContainer,
  BannerPaginationWrapper,
  BannerTitle,
  HomepageAdsWrapper,
  IconButtonStyled,
  ImageContainer,
  NextIcon,
  OuterContainer,
  OuterWrapper,
  SpanStyled,
} from "./styles";

let isNext = false; // Will need to know whether is coming from next or prev
let direction = "next";
let isAnimating = false;
let previousIndex = -1;
let adStyles = [];

function HomepageAds(props) {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [time, setTime] = useState(0);
  const [triggered, setTriggered] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const [ads, setAds] = useState([]);
  const numOfAds = props.homepageAds?.length;
  const [ref, inView] = useInView({
    threshold: 0,
  });

  // GTM tracking custom event for tracking homepage advertisement slider views
  const triggerCustomEvent = () => {
    if (inView) {
      sendTrackingEvent({
        event: "CE_view-ad-slide-homepage",
      });
    }
  };

  useEffect(() => {
    triggerCustomEvent();
  }, [inView]);

  useEffect(() => {
    props.getHomepageAds();
  }, []);

  const populateAdArray = () => {
    let newAds = [];

    if (props.homepageAds.length > 1) {
      props.homepageAds?.map((ad) => {
        for (var i = 0; i < 3; i++) {
          // For each ad, add another 2 same ad as right padding
          newAds.push(ad);
        }
      });
    }

    setAds([...newAds]);
  };

  const computeAdStyles = (currentIndex, direction) => {
    let newStyles = [];

    // Original position for each ads
    for (var i = 0; i < numOfAds; i++) {
      for (var j = 0; j < 3; j++) {
        if (j == 0) {
          // Left
          newStyles.push({
            width: "0%",
            left: `0%`,
            opacity: 0,
            transition: "", // Hide animation
          });
        } else if (j == 1) {
          // Middle
          newStyles.push({
            width: "4%",
            left: `91%`,
            opacity: 0,
            transition: "",
          });
        } else {
          // Right
          newStyles.push({
            width: "4%",
            left: `91%`,
            opacity: 0,
            transition: "",
          });
        }
      }
    }

    if (direction == "next") {
      // User click/swipe next

      // The index of ad at the left side of current active ad
      const prevIndex1 =
        currentIndex - 1 < 0 ? previousIndex * 3 : (currentIndex - 1) * 3;
      const endPrevIndex1 = prevIndex1 + 3; // For each ad, need loop 3 times as there are 2 extra same ad as right padding

      for (var i = prevIndex1; i < endPrevIndex1; i++) {
        if (i - prevIndex1 == 1) {
          // Middle
          // Collapse with animation
          newStyles[i] = {
            width: "0%",
            left: `0%`,
          };
        }
      }

      // Current ad
      const startIndex1 = currentIndex * 3;
      const endIndex1 = startIndex1 + 3;

      for (var i = startIndex1; i < endIndex1; i++) {
        if (i - startIndex1 == 1) {
          // Middle
          // Expand with animation
          newStyles[i] = {
            width: "90%",
            left: `0%`,
          };
        }
      }

      // Next first ad
      const pivotIndex2 =
        currentIndex + 2 > numOfAds
          ? currentIndex + 2 - numOfAds // Carousel/circle
          : currentIndex + 2;
      const startIndex2 = (pivotIndex2 - 1) * 3;
      const endIndex2 = startIndex2 + 3;

      for (var i = startIndex2; i < endIndex2; i++) {
        if (i - startIndex2 == 2) {
          // Right side
          newStyles[i] = {
            width: "4%",
            left: `91%`,
          };
        }
      }

      // Next second ad
      const pivotIndex3 =
        currentIndex + 3 > numOfAds
          ? currentIndex + 3 - numOfAds
          : currentIndex + 3;
      const startIndex3 = (pivotIndex3 - 1) * 3;
      const endIndex3 = startIndex3 + 3;

      for (var i = startIndex3; i < endIndex3; i++) {
        if (i - startIndex3 == 2) {
          // Right side
          newStyles[i] = {
            width: "4%",
            left: `96%`,
          };
        }
      }

      // Next third ad
      const pivotIndex4 =
        currentIndex + 4 > numOfAds
          ? currentIndex + 4 - numOfAds
          : currentIndex + 4;
      const startIndex4 = (pivotIndex4 - 1) * 3;
      const endIndex4 = startIndex4 + 3;

      for (var i = startIndex4; i < endIndex4; i++) {
        if (i - startIndex4 == 2) {
          // Right side
          newStyles[i] = {
            width: "4%",
            left: `101%`,
            transition: endIndex4 - 1 == i ? "" : "left 1s",
          };
        }
      }
    } else {
      // Previous
      const prevIndex1 =
        currentIndex - 1 < 0 ? (numOfAds - 1) * 3 : (currentIndex - 1) * 3;
      const endPrevIndex1 = prevIndex1 + 3;

      for (var i = prevIndex1; i < endPrevIndex1; i++) {
        if (i - prevIndex1 == 1) {
          // Middle
          // Collapse without animation
          newStyles[i] = {
            width: "0%",
            left: `0%`,
            transition: "",
          };
        }
      }

      // Current ad
      const startIndex1 = currentIndex * 3;
      const endIndex1 = startIndex1 + 3;

      for (var i = startIndex1; i < endIndex1; i++) {
        if (i - startIndex1 == 1) {
          // Middle
          // Expand with animation
          newStyles[i] = {
            width: "90%",
            left: `0%`,
          };
        }
      }

      // Next first ad
      const pivotIndex2 =
        currentIndex + 2 > numOfAds
          ? currentIndex + 2 - numOfAds
          : currentIndex + 2;
      const startIndex2 = (pivotIndex2 - 1) * 3;
      const endIndex2 = startIndex2 + 3;

      for (var i = startIndex2; i < endIndex2; i++) {
        if (i - startIndex2 == 1) {
          // Middle, collapse to right with animation
          newStyles[i] = {
            width: "4%",
            left: `91%`,
          };
        } else if (i - startIndex2 == 2) {
          // Right side, hide at right side with no animation
          newStyles[i] = {
            width: "4%",
            left: `91%`,
            opacity: 0,
            transition: "",
          };
        }
      }

      // Next second ad
      const pivotIndex3 =
        currentIndex + 3 > numOfAds
          ? currentIndex + 3 - numOfAds
          : currentIndex + 3;
      const startIndex3 = (pivotIndex3 - 1) * 3;
      const endIndex3 = startIndex3 + 3;

      for (var i = startIndex3; i < endIndex3; i++) {
        if (i - startIndex3 == 2) {
          // Right side
          newStyles[i] = {
            width: "4%",
            left: `96%`,
          };
        }
      }

      const pivotIndex4 =
        currentIndex + 4 > numOfAds
          ? currentIndex + 4 - numOfAds
          : currentIndex + 4;
      const startIndex4 = (pivotIndex4 - 1) * 3;
      const endIndex4 = startIndex4 + 3;

      for (var i = startIndex4; i < endIndex4; i++) {
        if (i - startIndex4 == 2) {
          // Right side
          newStyles[i] = {
            width: "4%",
            left: `101%`,
            transition: startIndex4 == previousIndex * 3 ? "" : "left 1s",
          };
        }
      }
    }

    adStyles = [...newStyles];
  };

  const prev = (clicked) => {
    if (isAnimating) return;
    isAnimating = true;
    direction = "prev";
    isNext = false;
    const prevIndex = currentIndex - 1 < 0 ? numOfAds - 1 : currentIndex - 1;

    if (clicked) {
      // GTM tracking custom event for tracking clicks of navigation buttons for ads on homepage
      sendTrackingEvent({
        event: "CE_click-arrow-previous-homepage-ad-slide",
      });
    }

    setTimeout(() => {
      isAnimating = false; // Release locking after 1010 ms
    }, 1010);

    setTriggered(!triggered);

    previousIndex = currentIndex;
    computeAdStyles(prevIndex, direction);
    setCurrentIndex(prevIndex);
  };

  const next = (clicked) => {
    if (isAnimating) return;
    isAnimating = true;
    direction = "next";
    isNext = true;
    const nextIndex = (currentIndex + 1) % numOfAds;

    if (clicked) {
      // GTM tracking custom event for tracking clicks of navigation buttons for ads on homepage
      sendTrackingEvent({
        event: "CE_click-arrow-next-homepage-ad-slide",
      });
    }

    setTimeout(() => {
      isAnimating = false; // Release locking after 1050 ms
    }, 1010);

    setTriggered(!triggered);

    previousIndex = currentIndex;
    computeAdStyles(nextIndex, direction);
    setCurrentIndex(nextIndex);
  };

  const getAdStyle = (index) => {
    try {
      return adStyles[index];
    } catch (error) {
      //
    }

    return {};
  };

  // To jump start
  useEffect(() => {
    // Tempo
    if (props.homepageAds.length > 1) {
      if (currentIndex == -1) {
        populateAdArray();
        computeAdStyles(0, direction);
        setCurrentIndex(0);
        next();
      }
    }
  }, [currentIndex, props.homepageAds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);

      if (mouseIn === false) {
        next();
      }
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [time, triggered, mouseIn]);

  const screenMobile = useMediaQuery("(min-width:768px)");

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // The required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe)
      if (isLeftSwipe) {
        next();
      } else {
        prev();
      }
  };

  // Add delay to the numbering
  const [displayCurrentIndex, setDisplayCurrentIndex] = useState(currentIndex);

  useEffect(() => {
    setTimeout(() => {
      setDisplayCurrentIndex(currentIndex + 1);
    }, 300);
  }, [currentIndex]);

  const handleClick = (params) => {
    const url = params.urlLink;

    window.open(url);

    // GTM tracking custom event for tracking clicks of advertisements on homepage
    sendTrackingEvent({
      event: "CE_click-ad-slide-homepage",
      header: params.title,
      "sub-header": params.description,
      url: url,
    });
  };

  return Array.isArray(ads) && ads.length > 0 ? (
    <OuterWrapper>
      {currentIndex != -1 ? (
        <OuterWrapper ref={ref}>
          <Fade in={true}>
            <HomepageAdsWrapper
              container
              justifyContent="center"
              alignItems="center"
            >
              {screenMobile ? (
                <IconButtonStyled
                  $transform="true"
                  disableRipple
                  onClick={() => prev(true)}
                  size="small"
                >
                  <NextIcon $transform="true" />
                </IconButtonStyled>
              ) : null}
              <OuterContainer>
                {ads?.map((image, index) => {
                  return (
                    <Atag
                      onMouseEnter={() => {
                        setMouseIn(true);
                      }}
                      onMouseLeave={() => {
                        setMouseIn(false);
                      }}
                      rel="noopener noreferrer"
                      key={image?.node?.title + index}
                      onTouchStart={onTouchStart}
                      onTouchMove={onTouchMove}
                      onTouchEnd={onTouchEnd}
                      onClick={() => handleClick(image?.node)}
                      style={{
                        cursor: "pointer",
                        overflow: "visible",
                        position: "absolute",
                        width: screenMobile ? "100%" : "320px",
                        height: screenMobile ? "100%" : "280px",
                        transition: "width 1s, left 1s",
                        ...getAdStyle(index),
                      }}
                    >
                      <ImageContainer
                        container
                        alignItems="flex-end"
                        style={{
                          backgroundImage: `url(${image?.node?.image})`,
                        }}
                      >
                        <BannerDetailsWrapper
                          container
                          direction="row"
                          justifyContent="space-between"
                          $currentIndex={currentIndex * 3 + 1 === index}
                        >
                          <BannerDescriptionWrapper
                            container
                            display="inline-block"
                            direction="column"
                            justifyContent="flex-end"
                            item
                          >
                            <BannerChipWrapper>
                              <BannerChipStyled label={image?.node?.chipText} />
                            </BannerChipWrapper>
                            <BannerTitle variant="h3_bold_L">
                              {image?.node?.title}
                            </BannerTitle>
                            <BannerDescription
                              variant="body2_L"
                              display="block"
                              flexWrap="nowrap"
                            >
                              {image?.node?.description}
                            </BannerDescription>
                          </BannerDescriptionWrapper>
                          <BannerPaginationWrapper
                            container
                            item
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <BannerPaginationContainer
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <BannerPaginationActiveText>
                                {displayCurrentIndex}
                                <SpanStyled whitespace>/</SpanStyled>
                                <SpanStyled>{numOfAds}</SpanStyled>
                              </BannerPaginationActiveText>
                            </BannerPaginationContainer>
                          </BannerPaginationWrapper>
                        </BannerDetailsWrapper>
                      </ImageContainer>
                    </Atag>
                  );
                })}
              </OuterContainer>
              {screenMobile ? (
                <IconButtonStyled
                  onClick={() => next(true)}
                  disableRipple
                  size="small"
                >
                  <NextIcon />
                </IconButtonStyled>
              ) : null}
            </HomepageAdsWrapper>
          </Fade>
        </OuterWrapper>
      ) : null}
    </OuterWrapper>
  ) : (
    <Fragment></Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    homepageAds: state.jobs.homepageAds,
    homepageLogo: state.jobs.homepageLogo,
    trendingChip: state.jobs.trendingChip,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomepageAds: bindActionCreators(getHomepageAds, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageAds);
