import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

export const ImageStyled = styling("img")({
  width: "5rem",
  height: "5rem",
  borderRadius: "0.625rem",
  objectFit: "contain",
});

export const ImageContainer = styling(Grid)((props) => ({
  backgroundColor: Color.white,
  transitionDuration: "0.5s",
  transform: props.transform,
  height: "100px",
  width: "100px",
  opacity: props.opacity,
  borderRadius: "20px",
  zIndex: 1,
  right: props.right,

  ":hover": {
    cursor: "none",
  },

  [props.theme.breakpoints.down("lg")]: {
    transitionDuration: "2.5s",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: "80px !important",
    width: "80px !important",
  },
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "20px",
    padding: "5px",
    border: "3px solid black",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 5,
    cursor: "none",
  },
});

export const LinkStyled = styling("a")((props) => ({
  pointerEvents: props["aria-disabled"] ? "none" : "auto",
}));
