import { styled as styling } from "@mui/material/styles";
import styled from "styled-components";
import withTheme from "@mui/styles/withTheme";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";
import Chip from "@mui/material/Chip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";

// Grid Components //
export const OuterWrapper = styling(Grid)({
  backgroundColor: Color.homepageGrey,
  minHeight: "400px",
});

export const HomepageAdsWrapper = styling(Grid)((props) => ({
  padding: "40px 100px 40px 100px",
  maxWidth: "1440px",
  margin: "auto",
  backgroundColor: Color.homepageGrey,
  minHeight: "330px",

  [props.theme.breakpoints.down("lg")]: {
    padding: "0px 24px 40px 24px",
  },
}));

export const OuterContainer = styling(Grid)((props) => ({
  maxHeight: "500px",
  position: "relative",
  overflow: "hidden",
  width: "90%",
  height: "335px",

  [props.theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));

export const ImageContainer = styling(Grid)({
  overflow: "hidden",
  backgroundPosition: "center",
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
  backgroundRepeat: "no-repeat",
  borderRadius: "10px",
});

export const BannerPaginationContainer = styling(Grid)((props) => ({
  backgroundColor: `rgba(255, 255, 255, 0.1)`,
  height: "25px",
  width: "44px",
  borderRadius: "4px",
  overflow: "hidden",

  [props.theme.breakpoints.down("tablet")]: {
    width: "40px",
    height: "26px",
  },
}));

export const BannerPaginationWrapper = styling(Grid)({
  width: "20%",
  overflow: "hidden",
});

export const BannerDescriptionWrapper = styling(Grid)((props) => ({
  width: "100%",
  overflow: "hidden",
  maxWidth: "650px",
  minWidth: "650px",

  [props.theme.breakpoints.down("lg")]: {
    maxWidth: "460px",
    minWidth: "460px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    maxWidth: "225px",
    minWidth: "225px",
    height: "100%",
  },
}));

export const BannerDetailsWrapper = withTheme(styled(Grid)`
  width: 100%;
  position: relative;
  padding: 50px 36px 24px 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  transition-duration: 1.5s;
  max-height: 250px;
  border-radius: 10px;
  opacity: ${(props) => (props.$currentIndex === true ? "1" : 0)};
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);

  ${(props) => props.theme.breakpoints.down("tablet")} {
    padding: 0px 12px 12px 12px;
    max-height: 50%;
  }
`);

export const BannerChipWrapper = styling(Grid)({
  //
});

export const Atag = styling("a")({
  minHeight: "330px",
});

// Chip Component //
export const BannerChipStyled = styling(Chip)((props) => ({
  borderRadius: "4px",
  border: `1px solid ${Color.white}`,
  color: Color.white,
  fontWeight: "bold",
  fontSize: "12px",
  padding: "0px",
  overflow: "hidden",
  height: "25px",
  backgroundColor: `rgba(255, 255, 255, 0.2)`,

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "10px",
    height: "20px",
  },
}));

// Typography Components //
export const BannerTitle = styling(Typography)((props) => ({
  color: Color.white,
  overflow: "hidden",
  textOverflow: "ellipsis",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "10px",
  },
}));

export const BannerDescription = styling(Typography)((props) => ({
  color: Color.white,
  overflow: "hidden",
  textOverflow: "ellipsis",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "10px",
  },
}));

export const BannerPaginationActiveText = styling(Typography)((props) => ({
  color: Color.white,
  fontSize: "10px",
  fontWeight: "700",
  overflow: "hidden",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "10px",
  },
}));

// Icon Components //
export const NextIcon = withTheme(styled(ArrowForwardIosIcon)`
  && {
    transform: ${(props) =>
      props.$transform === "true" ? "rotate(180deg)" : null};
    font-size: 1rem;
  }
`);

export const BackIcon = styling(ArrowBackIosIcon)({
  //
});

// Icon Button Component //
export const IconButtonStyled = withTheme(styled(IconButton)`
  && {
    transform: ${(props) =>
      props.$transform === "true" ? "translateX(50%)" : "translateX(-50%)"};
    border: "1px solid ${Color.black}";
    background-color: ${Color.white};
    z-index: 10;
    width: 34px;
    height: 34px;
  }
`);

// Span Component //
export const SpanStyled = styling("span")((props) => ({
  color: `rgba(255, 255, 255, 0.38)`,
  marginLeft: props.whitespace ? "6px" : "",
  marginRight: props.whitespace ? "6px" : "",
}));
