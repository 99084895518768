import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import * as config from "../../../../config/config";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  CustomTooltip,
  ImageContainer,
  ImageStyled,
  LinkStyled,
} from "./styles";

function HomepageMobileLogo(props) {
  const { id, imageList, imagePlaceholder } = props;

  const [hasError, setHasError] = useState(false);

  const sequence = [3, 1, 4, 0, 2];
  const sequence2 = [5, 8, 7, 6];

  const [pointer, setPointer] = useState(0);
  const [pointer2, setPointer2] = useState(0);

  // For the pulsing animation 1
  const [time, setTime] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);

      if (pointer === 4) {
        setPointer(0);
      } else {
        setPointer(pointer + 1);
      }
    }, 1700);
    return () => {
      clearTimeout(timer);
    };
  }, [time, pointer]);

  // For the pulsing animation 2
  const [time2, setTime2] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime2(time2 + 1);

      if (pointer2 === 3) {
        setPointer2(0);
      } else {
        setPointer2(pointer2 + 1);
      }
    }, 1400);
    return () => {
      clearTimeout(timer);
    };
  }, [time2, pointer2]);

  const handleError = () => {
    setHasError(true);
  };

  const handleMobileClickCompany = (companyId) => {
    sendTrackingEvent({
      event: "CE_click-company-icon-homepage",
      "company-id": companyId,
    });

    Cookies.set("click-source", "company_icon");
  };

  let clickableLink = `/companies/${imageList?.node?.company?.slug?.trim()}`;

  if (clickableLink.endsWith("-")) {
    clickableLink = clickableLink.slice(0, -1);
  }

  // For showing the logo
  const timeout = [200, 1000, 3000, 1800, 3400, 3800, 600, 1400, 2600];

  return (
    <Fade in={true} timeout={timeout[id]}>
      <Grid>
        <CustomTooltip followCursor title={imageList?.node?.companyName}>
          <LinkStyled
            href={clickableLink}
            aria-disabled={hasError || !imageList}
          >
            <ImageContainer
              onClick={() =>
                handleMobileClickCompany(imageList?.node?.companyId)
              }
              container
              justifyContent="center"
              alignItems="center"
              opacity={
                sequence[pointer] === id || sequence2[pointer2] === id ? 1 : 0.5
              }
              transform={
                sequence[pointer] === id || sequence2[pointer2] === id
                  ? `scale(1.2)`
                  : ""
              }
            >
              {imageList && !hasError ? (
                <ImageStyled
                  src={"https:" + `${imageList?.node?.logo}`}
                  alt={"https:" + `${imageList?.node?.order}`}
                  onError={handleError}
                />
              ) : (
                <ImageStyled
                  src={config.assetDomain + imagePlaceholder[id]}
                  alt="company logo placeholder"
                />
              )}
            </ImageContainer>
          </LinkStyled>
        </CustomTooltip>
      </Grid>
    </Fade>
  );
}

export default HomepageMobileLogo;
