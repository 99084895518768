import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";

// Grid
export const TitleGrid = styling(Grid)((props) => ({
  height: "100%",
  maxHeight: "180px",

  [props.theme.breakpoints.down("lg")]: {
    maxHeight: "1000px",
  },
}));

export const HeaderGrid = styling(Grid)({
  width: "100%",
});

export const AnimatedTextGrid = styling(Grid)((props) => ({
  minWidth: "220px",
  maxWidth: "220px",
  height: "auto",
  position: "absolute",
  transitionDuration: "0.35s",
  opacity: props.opacity,
  marginLeft: "145px",

  [props.theme.breakpoints.down("lg")]: {
    marginLeft: "145px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    marginLeft: "85px",
  },
}));

export const BlankGrid = styling(Grid)((props) => ({
  minWidth: "220px",

  [props.theme.breakpoints.down("lg")]: {
    minWidth: "220px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    minWidth: "130px",
  },
}));

// Typography
export const TitleStyled = styling(Typography)((props) => ({
  color: Color.black,
  fontWeight: "bold",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "28px",
  },
}));

export const TitleStyledAnimated = styling(Typography)((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "bold",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "28px",
  },
}));
