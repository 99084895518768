import Color from "../../../assets/colors";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { animated } from "react-spring";

// Typography Components //
export const Title = styled(Typography)`
  && {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
`;

export const ContinueText = styled(Typography)`
  && {
    font-size: 1.1rem;
    text-align: center;
    text-decoration: underline;
    color: ${Color.hiredlyPurple};
  }
`;

// Grid Components //
export const OverlayBackground = styled(Grid)`
  && {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;
  }
`;

export const AppDownloadContent = styled(animated(Grid))`
  && {
    background-color: ${Color.white};
    width: 100%;
    height: 42vh;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 15px 0;
  }
`;

export const TitleContainer = styled(Grid)`
  && {
    margin-bottom: 20px;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }
`;

export const DownloadAppButtonContainer = styled(Grid)`
  && {
    padding: 10px 0;
    margin: 15px auto 15px;
    width: 100%;
    text-align: center;
  }
`;

export const MobileWebButtonContainer = styled(Grid)`
  && {
    text-align: center;
    width: 100%;
  }
`;
